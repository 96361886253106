/*** New design variable ***/

// Image dir
$img-dir: $static_url + "img";

// Fonts
$fonts-dir: $static_url + "css/fonts";

// Spacing
$spacingXs: 5px !default;
$spacingS: 10px !default;
$spacingM: 15px !default;
$spacingL: 20px !default;
$spacingXl: 30px !default;

// Colors
$purple: #CC9FEA !default;
$white: #FFFFFF !default;
$gray: #7F8C8D !default;
$orange: #FFD37F !default;
$black: #000000 !default;
$blue: #dbf2ff !default;
$yellow: #fcf8e3 !default;
$red: #da4d4d !default;
$green: #4ac780 !default;

// Extended palette
$gray1: #888888 !default;
$gray2: #515151 !default;
$gray3: #ECF0F1 !default;
$gray4: #484848 !default;
$gray5: #ebeaea !default;
$gray6: #f8f8f8 !default;

$purple: #9013FE !default;
$purple1: #A846FE !default;
$purple2: #C078FE !default;
$purple3: #D8ABFF !default;
$purple4: #EFDEFF !default;


//$purple1: #B36FE0 !default;
//$purple2: #9A3FD5 !default;
//$purple3: #432b42 !default;
//$purple4: #f2ecf8 !default;
//$purple5: #88698e !default;
//$purple6: #8047be !default;

$orange1: #FFBD40 !default;
$orange2: #FFA700 !default;

$black1: lighten($black, 18%) !default;

$green1: rgba($green, 0.2) !default;

// Border Radius
$borderRadiusXs: 2px !default;
$borderRadiusS: 3px !default;
$borderRadiusMS: 5px !default;
$borderRadiusM: 6px !default;
$borderRadiusL: 10px !default;

// Typography
$baseFontSize: 13px !default;
$smallFontSize: $baseFontSize - 1;

//Uses google font service
$baseFontFamily: "Open Sans", sans-serif !default;
$baseLineHeightFontMultiplier: 1.5 !default;
$baseFontColor: lighten($black, 15%) !default;

//text color
$txtGray: #CCCCCC !default;
$txtGray1: #999999 !default;
$txtGray2: #333333 !default;
$txtPurple: #9013FE !default;
$txtPurple1: #6401bb !default;

// Forms
$inputBackground: $white !default;
$inputBorder: #c5c7c5 !default;
$inputBorderFocus: $purple1 !default;
$inputDisabledBackground: $gray3 !default;
$inputSelectedControl: #344957 !default;
$inputBaseLineHeight: 1.3em !default;

// Shared variables for calculations
// -------------------------
//This MUST match the variables in navigation.js
$group_header_height: 0px;
$group_editable_header_height: 25px;

// Form states and alerts
// -------------------------
$warningText: #c09853 !default;
$warningBackground: #fcf8e3 !default;
$warningBorder: darken(adjust-hue($warningBackground, -10), 3%) !default;

$errorText: #d54243 !default;
$errorBackground: lighten($errorText, 58%) !default;
$errorBorder: $errorText !default;

// Layout
$siteWidth: 980px !default;
$breakpointM: 768px !default;

// breakpoint for tablets
$breakpointS: 480px !default;
// breakpoint for smartphones

// Responsive
$pageWidth: 85% !default;
$pageWidthM: 90% !default;
// when reaching the tablet breakpoint
$pageWidthS: 97% !default;
// when reaching the smartphone breakpoint

/**
 * Other variable
 **/

// Usage variables
$textSelectionBackgroundColor: $orange;
$closebuttonColor: $gray;

// panels
// below this size we scroll
$small_screen: 980px;

// these variables are also in assemblPanel.js
$idea_panel_grid_size: 3;
$message_panel_grid_size: 5;
$navigation_panel_grid_size: 4;
$clipboard_grid_size: 3;
$context_panel_grid_size: 8;
$synthesis_panel_grid_size: 8;
$default_grid_size: 3;

// 2/1 ratio for message/idea
// the number of grid units taken by basic setup of navigation+messages+ideaPanel or TableOfIdeas+Messages (in admin mode)
$min_num_grid_units: $default_grid_size + $synthesis_panel_grid_size;
// the number of grid units taken by largest panel
$max_panel_unit_size: $message_panel_grid_size + $idea_panel_grid_size;
// the number of grid units taken by largest group. Assume one message + 3 smaller panels.
$max_group_unit_size: $synthesis_panel_grid_size + $idea_panel_grid_size + $message_panel_grid_size + $clipboard_grid_size;

$grid_unit_min_size: calc($small_screen / $min_num_grid_units);
// huge screen
$max_num_grid_units: calc(2560px / $grid_unit_min_size);
// message-list min 5, nav3, idea 4

//badges

$panelBackground: $white;
$annotationColor: $purple4;

// table of ideas
$tableOfIdeasRowHeight: 36px;

$infobar_height: 50px;
