// The right click menu
// ====================
.contextmenu {
    position: absolute;
    display: inline-block;
    padding: 10px * 1.2 10px * 1.5;
    border: solid 1px #e4e4e3;
    box-shadow: 0 2px 3px #ccc;
    border-radius: 5px;
    background-color: #fff;
}

// A link within the .contextmenu
// ------------------------------
.contextmenu-item {
    display: block;
    line-height: 22px;
    cursor: pointer;
}
.contextmenu-item, .contextmenu-item a {
    color: $gray1;
    &:hover, &.is-hovered {
        color: $black;
    }
}
